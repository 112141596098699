import React, { useState, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";

const IdentityDetails = ({
  userVerificationDetail,
  taskDetail,
  identityCheckInfo,
  getIndetityInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [outsidePic, setOutsidePic] = useState([]);
  const [boardNamePics, setBoardNamePics] = useState([]);
  const [neighbourInfo, setNeighbourInfo] = useState([]);
  const [passPortPics, setPassPortPics] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editablePassport, setEditablePassport] = useState(false);
  const [editableAdhar, setEditableAdhar] = useState(false);
  const [editablePan, setEditablePan] = useState(false);
  const [uploadProofAdhar, SetUploadProofAdhar] = useState([]);
  const [uploadProofPan, SetUploadProofPan] = useState([]);
  const [uploadProofDL, SetUploadProofDL] = useState([]);
  const [uploadProofPP, SetUploadProofPP] = useState([]);

  useEffect(() => {
    getDiscreteCallInfo(userVerificationDetail);
  }, [userVerificationDetail]);

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then((res) => {
        const data = res?.data?.docs[0];
        if (data) {
          setOutsidePic(data?.adhaarCard?.upload || []);
          setBoardNamePics(data?.panCard?.upload || []);
          setNeighbourInfo(data?.drivingLicence?.upload || []);
          setPassPortPics(data?.passport?.upload || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  useEffect(() => {
    setData(identityCheckInfo);
  }, [identityCheckInfo]);

  const onInputChange = (e, index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        adhaarCard: {
          ...newData[index].adhaarCard,
          [e.target.name]: e.target.value,
        },
        panCard: {
          ...newData[index].panCard,
          [e.target.name]: e.target.value,
        },
        drivingLicence: {
          ...newData[index].drivingLicence,
          [e.target.name]: e.target.value,
        },
        passport: {
          ...newData[index].passport,
          [e.target.name]: e.target.value,
        },
      };
      return newData;
    });
  };

  const handleFileUpload = async (event, category) => {
    const files = event.files;
    const fileData = [];
    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "outside":
        setOutsidePic([...outsidePic, ...uploadedFileUrls]);
        toast.success("Outside Pic has successfully uploaded");
        break;
      case "boardName":
        setBoardNamePics([...boardNamePics, ...uploadedFileUrls]);
        toast.success("Board Pic has successfully uploaded");
        break;
      case "neighbour":
        setNeighbourInfo([...neighbourInfo, ...uploadedFileUrls]);
        toast.success("Neighbour Pic has successfully uploaded");
        break;
      case "passport":
        setPassPortPics([...passPortPics, ...uploadedFileUrls]);
        toast.success("Neighbour Pic has successfully uploaded");
        break;
      case "uploadProofAdhar":
        SetUploadProofAdhar([...uploadProofAdhar, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;

      case "uploadProofPan":
        SetUploadProofPan([...uploadProofPan, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;

      case "uploadProofDL":
        SetUploadProofDL([...uploadProofDL, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;

      case "uploadProofPP":
        SetUploadProofPP([...uploadProofPP, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
    }
  };

  const updateDiscreteCalls = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      adhaarCard: {
        ...data[index]?.adhaarCard,
        upload: outsidePic,
        remark: data[index]?.adhaarCard?.remark,
        uploadProofUrl: [
          ...data[index]?.adhaarCard?.uploadProofUrl,
          ...uploadProofAdhar,
        ],
      },
      panCard: {
        ...data[index]?.panCard,
        upload: boardNamePics,
        remark: data[index]?.panCard?.remark,
        uploadProofUrl: [
          ...data[index]?.panCard?.uploadProofUrl,
          ...uploadProofPan,
        ],
      },
      drivingLicence: {
        ...data[index]?.drivingLicence,
        upload: neighbourInfo,
        remark: data[index]?.drivingLicence?.remark,
        uploadProofUrl: [
          ...data[index]?.drivingLicence?.uploadProofUrl,
          ...uploadProofDL,
        ],
      },
      passport: {
        ...data[index]?.passport,
        upload: passPortPics || [],
        remark: data[index]?.passport?.remark,
        uploadProofUrl: [
          ...data[index]?.passport?.uploadProofUrl,
          ...uploadProofPP,
        ],
      },
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Identity form successfully submitted");
      if (flag === "false") {
        getIndetityInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleEditPassport = () => {
    setEditablePassport(!editablePassport);
  };
  const handleEditAdhar = () => {
    setEditableAdhar(!editableAdhar);
  };
  const handleEditPan = () => {
    setEditablePan(!editablePan);
  };

  const updateCheckStatus = async (status, e, id, index, type) => {
    e.preventDefault();
    const body = {
      status: status,
      type: type,
    };
    try {
      await ApiService.patch(`identityCheck/identity/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status, type);
      getIndetityInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  // const updateTaskItem = async (id, status, type) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: type,
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            // key={Math.random().toString(36).substr(2, 9)}
            title={item?.qualification}
            className={`border-2 ${index === 0 ? "mt-0" : "mt-4"}`}
          >
            <div>
              <form>
                <div className="border-2 p-3">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.adhaarCard?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    index,
                                    "adhaarCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    index,
                                    "adhaarCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Adhaar Card</h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="adharName"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.adhaarCard?.adharName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Adhaar Number
                        </label>
                        <InputText
                          className="form-control"
                          keyfilter="int"
                          name="adhaarNumber"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.adhaarCard?.adhaarNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {outsidePic?.length === 0 || editableAdhar === true ? (
                      <FileUpload
                        name="outsideUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "outside", index)
                        }
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}

                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {outsidePic?.map((fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`Outside_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`Outside_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Remark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="remarkOfAdhaar"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.adhaarCard?.remarkOfAdhaar || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof
                    </label>
                    {item?.adhaarCard?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofAdhar")
                        }
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.adhaarCard?.uploadProofUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      outsidePic?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEditAdhar(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.adhaarCard?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.adhaarCard?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.adhaarCard?.status)}</div>
                    )}
                  </div>
                </div>
                <div className="border-2 p-3 mt-2">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.panCard?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    index,
                                    "panCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    index,
                                    "panCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Pan Card</h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="panName"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.panCard?.panName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Pan Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="panNumber"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.panCard?.panNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {boardNamePics?.length === 0 || editablePan === true ? (
                      <FileUpload
                        name="outsideUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "boardName")}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {boardNamePics?.map((fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`Outside_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`Outside_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Remark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="remarkOfPan"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.panCard?.remarkOfPan || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof
                    </label>
                    {item?.panCard?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofPan")
                        }
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.panCard?.uploadProofUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      boardNamePics?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEditPan(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.panCard?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.panCard?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.panCard?.status)}</div>
                    )}
                  </div>
                </div>
                <div className="border-2 p-3 mt-2">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.drivingLicence?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    index,
                                    "drivingLicence"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    index,
                                    "drivingLicence"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Driving Licence </h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="drivingName"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.drivingLicence?.drivingName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Driving Licence Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="drivingLicenceNumber"
                          onChange={(e) => onInputChange(e, index)}
                          value={
                            item?.drivingLicence?.drivingLicenceNumber || ""
                          }
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {neighbourInfo?.length === 0 || editable === true ? (
                      <FileUpload
                        name="outsideUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "neighbour")}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {neighbourInfo?.map((fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`Outside_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`Outside_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Remark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="remarkOfDL"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drivingLicence?.remarkOfDL || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof
                    </label>
                    {item?.drivingLicence?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofDL")
                        }
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.drivingLicence?.uploadProofUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      neighbourInfo?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.drivingLicence?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.drivingLicence?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.drivingLicence?.status)}</div>
                    )}
                  </div>
                </div>
                <div className="border-2 p-3 mt-2">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.passport?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    index,
                                    "passport"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    index,
                                    "passport"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Passport </h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="passportName"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.passport?.passportName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Passport Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="passportNumber"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.passport?.passportNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {passPortPics?.length === 0 || editablePassport === true ? (
                      <FileUpload
                        name="outsideUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "passport")}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {passPortPics?.map((fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`Outside_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`Outside_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Remark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="remarkOfPassport"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.passport?.remarkOfPassport || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof
                    </label>
                    {item?.passport?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofPan")
                        }
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.passport?.uploadProofUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      passPortPics?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEditPassport(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.passport?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, index);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.passport?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.passport?.status)}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default IdentityDetails;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../services/APIServices";
import { toast } from "react-toastify";
// import { useFormik } from "formik";
// import * as Yup from "yup";
import coin_logo from "../../../assets/images/coin_logo.png";
import { TabPanel, TabView } from "primereact/tabview";
import { Password } from "primereact/password";
function Login() {
  const bannerlogo = process.env.REACT_APP_S3_URL
    ? `${process.env.REACT_APP_S3_URL}/public/assets/loginlogo.png`
    : coin_logo;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const validationSchema = Yup.object({
  //   email: Yup.string()
  //     .email("Invalid email format")
  //     .required("Email is required"),
  // });
  // const formik = useFormik({
  //   initialValues: {
  //     email: null,
  //     userName: null,
  //     password: null,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: () => {
  //     setIsLoading(true);
  //   },
  // });
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex.index);
  };

  const checkScreenHeight = window.screen.height;
  let screen = "";
  if (checkScreenHeight <= 950) {
    screen = "h-screen";
    // screen = "h-[950px]";
  } else {
    screen = "h-screen";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === null && userName === null && password === null) {
      toast.error("Please enter your email or username, and password.");
    } else {
      setIsLoading(true);
      await ApiService.post("login", {
        email: email,
        userName: userName,
        password: password,
      })
        .then(async (res) => {
          if (email === null) {
            toast.success("LoggedIn Successfully");
            localStorage.setItem("token", res?.data?.token); //JSON.stringify()
            localStorage.setItem("expiry", res?.data?.expiry);
            localStorage.setItem("refreshExpiry", res?.data?.refreshExpiry);
            localStorage.setItem("refreshToken", res?.data?.refreshToken);
            localStorage.setItem("user", JSON.stringify(res?.data?.user));
            if ((await res?.data?.user?.role) === "BCA") {
              navigate("/bca");
            } else if ((await res?.data?.user?.role) === "company") {
              navigate("/company");
            } else if ((await res?.data?.user?.role) === "user") {
              navigate("/userLink");
            } else if ((await res?.data?.user?.role) === "CCTAdmin") {
              navigate("/assign-bca");
            } else if ((await res?.data?.user?.role) === "BCAStaff") {
              navigate("/staff-member-dashboard");
            }
          } else {
            localStorage.setItem("userEmail", email);
            setIsLoading(false);

            navigate("/verify", {
              state: {
                email: email,
                formType: "login",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          toast.error(err?.response?.data?.error);
        });
    }
  };

  return (
    <>
      <div className="layout-wrapper">
        <div className={`main-wrapper w-full ${screen}`}>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="loginbanner">
              <div className="p-[30%]">
                <img
                  src={bannerlogo}
                  alt="login-thumb"
                  className="h-[90%] w-[90%] "
                />
              </div>{" "}
            </div>
            <div className=" flex px-[20%] max-sm:px-[11%] py-[11%] min-h-screen max-sm:min-h-[67vh]">
              <form onSubmit={handleSubmit} className="w-full">
                <h3>Welcome!</h3>
                <p className="text-[15px] font-medium">
                  Enter your credentials to access your account
                </p>
                <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
                  <TabPanel header="Login with Email">
                    <div>
                      <label
                        htmlFor="email"
                        className="text-black text-[15px] font-light mt-3 lg:mt-5"
                      >
                        Enter your email address
                      </label>
                      <div className="flex flex-col items-start">
                        <input
                          type="email"
                          className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Login with UserName">
                    <div>
                      <label
                        htmlFor="userName"
                        className="text-black text-[15px] font-light mt-3 lg:mt-5"
                      >
                        Username
                      </label>
                      <div className="flex flex-col items-start">
                        <input
                          type="text"
                          className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          id="userName"
                          name="userName"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <label
                        htmlFor="password"
                        className="text-black text-[15px] font-light mt-3 lg:mt-5"
                      >
                        Password
                      </label>
                      <div className="flex flex-col items-start">
                        <Password
                          value={password}
                          className="w-full"
                          id="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          toggleMask
                        />
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full m-auto text-white h-[54px] bg-[#2D5AAF] rounded-[10px] shadow"
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Wait...
                      </>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
                {process.env.REACT_APP_ENABLE_USER_SIGNUP === "true" ? (
                  <>
                    <div className="flex justify-center mt-3">
                      <span className="text-black text-[13px] font-normal">
                        Dont Have an Account ?<br />
                      </span>{" "}
                      &nbsp;
                      <span
                        role="presentation"
                        className="text-sky-800 text-[13px] cursor-pointer font-normal"
                        onClick={() => navigate("/signup")}
                      >
                        Sign Up
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </form>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

import React, { useEffect } from "react";
import Toaster from "./Helper/Toaster.jsx";
import Routers from "./Routers.jsx";
import "bootstrap/dist/css/bootstrap.css";
function App() {
  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE;
  }, []);
  return (
    <>
      <Routers />
      <Toaster />
    </>
  );
}

export default App;

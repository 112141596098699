import React from "react";
import Signup from "../components/AuthPages/Signup/Signup.jsx";

export default function SignupPages() {
  return (
    <>
      <Signup />
    </>
  );
}

import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";

const ReferenceDetails = ({
  userVerificationDetail,
  taskDetail,
  referenceCheckInfo,
  getReferenceCheckInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const categories = [{ name: "Friends/Colleague" }, { name: "Others" }];

  const getReferenceInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "referenceCheck/link";
    } else {
      url = "referenceCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then((res) => {
        res?.data?.docs[0]; // Assuming there's only one item in the array
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const onInputChange = (e, index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        reference: {
          ...newData[index].reference,
          [e.target.name]: e.target.value,
        },
      };
      // Return the updated array
      return newData;
    });
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    setData(referenceCheckInfo);
  }, [referenceCheckInfo]);

  const updateReferenceDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "referenceCheck/link";
    } else {
      url = "referenceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      reference: data[index]?.reference,
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      getReferenceInfo(userVerificationDetail);
      toast.success("Reference details updated successfully");
      getReferenceCheckInfo(userVerificationDetail);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, e, id) => {
    e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.patch(`referenceCheck/reference/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getReferenceCheckInfo(userVerificationDetail);
      // setCheckStatus(status);
      // geteducationCheckInfo(userVerificationDetail);
      // navigate("/staff-member-dashboard");
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "reference",
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`border-2 ${index === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex justify-between">
                    {item?.reference?.status === "Pending" ? (
                      <div className="flex flex-row gap-1">
                        <div
                          role="presentation"
                          onClick={(e) => {
                            updateCheckStatus("Approved", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Approve"
                            severity="success"
                            icon="pi text-white pi-check-circle"
                            size="small"
                          />
                        </div>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            updateCheckStatus("Rejected", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Reject"
                            icon="pi text-white pi-times-circle"
                            severity="danger"
                            size="small"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>{getSeverity(item?.reference?.status)}</div>
                      </>
                    )}
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Type
                    </label>
                    <Dropdown
                      value={item?.reference?.type || ""}
                      onChange={(e) => onInputChange(e, index)}
                      options={categories}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="type"
                      name="type"
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Full Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="fullName"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.fullName || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Contact Number
                    </label>
                    <InputText
                      className="form-control"
                      keyfilter="int"
                      maxLength={10}
                      name="contactNumber"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.contactNumber || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      E-mail Address
                    </label>
                    <InputText
                      className="form-control"
                      type="email"
                      name="email"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.email || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      City
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="city"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.city || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      State
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="state"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.state || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Country
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="country"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.country || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Pincode
                    </label>
                    <InputText
                      className="form-control"
                      keyfilter="int"
                      maxLength={6}
                      name="pinCode"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.reference?.pinCode || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-2 mt-[10px]">
                  {flag === "false" && item?.reference?.status === "Pending" ? (
                    <>
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateReferenceDetails(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.reference?.status)}</div>
                    </>
                  ) : (
                    <div>{getSeverity(item?.reference?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default ReferenceDetails;

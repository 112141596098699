import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import ExperienceDetail from "../../StaffMember/ExperienceDetails.jsx";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

export default function ExperienceCheck({
  userVerificationRequestInfo,
  taskDetail,
  info,
  flag,
}) {
  const [selectedStaffForExperienceCheck, setSelectedStaffForExperienceCheck] =
    useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [experienceCheckInfo, setExperienceCheckInfo] = useState([]);
  const [experienceCheckId, setExperienceCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        setBCAStaffList(log?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
      }
    }
    getExperienceCheckInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  const getExperienceCheckInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setExperienceCheckInfo(res?.data?.docs);
        setExperienceCheckId(res?.data?.docs[0]?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createExperienceCheck = async () => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    setAddNewLoading(true);
    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      experience: {
        type:"",
        companyName: "",
        location: "",
        companyAddress: "",
        contactDetail: "",
        employmentCode: "",
        designation: "",
        tenure: "",
        salary: "",
        supervisorName: "",
        supervisorNumber: "",
        supervisorEmail: "",
        hrName: "",
        hrNumber: "",
        hrEmail: "",
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
        reportUrl: [],
      },
    };
    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Experience Added successfully");
        setAddNewLoading(false);
        getExperienceCheckInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setAddNewLoading(false);
      });
  };
  useEffect(() => {}, [experienceCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );
      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillExperienceStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.ExperienceTaskId
      );

      setSelectedStaffForExperienceCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForExperience = async () => {
      if (userVerificationRequestInfo?.ExperienceTaskId !== null) {
        await prefillExperienceStaff(
          userVerificationRequestInfo?.ExperienceTaskId
        );
      }
    };

    fetchStaffForExperience();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId: selectedStaffForExperienceCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Experience",
      };

      if (userVerificationRequestInfo?.ExperienceTaskId === null) {
        body.checkListItem = experienceCheckInfo;
        await createTask(body);
      } else {
        // body.BCAId = assignEducationTask.selectedStaff.BCAId;
        // body.assignedStaffMemberId = assignEducationTask.selectedStaff._id;
        body.checkListItem = experienceCheckInfo;
        let path = `task/${userVerificationRequestInfo?.ExperienceTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId: selectedStaffForExperienceCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      console.error("Error in API:", error);
      setAssignLoading(false);
    }
  };
  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.patch(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsAssigned = async () => {
    let body = {
      isAssigned: true,
    };
    ApiService.patch(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getExperienceCheckInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.patch(path, body);
    if (response) {
      toast.success("Task updated successfully");
      getExperienceCheckInfo(userVerificationRequestInfo?._id);
      info();
      setAssignLoading(false);
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Experience Check</h5>
          </div>
          <div className="flex max-sm:flex-col gap-2">
            {experienceCheckInfo.length > 0 &&
            (user?.role === "BCA" || user?.role === "Vendor") ? (
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForExperienceCheck}
                  onChange={(e) => {
                    setSelectedStaffForExperienceCheck(e.value);
                  }}
                  options={BCAStaffList}
                  optionLabel="firstName"
                  className="w-[148px] h-[45px] max-sm:w-full"
                  placeholder="Assign Staff"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={experienceCheckInfo.length === 0}
                  filter
                />
              ) : (
                ""
              )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              {experienceCheckInfo.length > 0 &&
              (user?.role === "BCA" || user?.role === "Vendor") ? (
                  <Button
                    label="Assign"
                    type="button"
                    loading={assignLoading}
                    onClick={() => handleCreateTaskAPI()}
                    className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                ) : (
                  ""
                )}
              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createExperienceCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {experienceCheckInfo.length > 0 &&
        experienceCheckInfo !== null &&
        experienceCheckId !== undefined &&
        experienceCheckId !== null &&
        experienceCheckInfo !== undefined && (
        <>
          <ExperienceDetail
            userVerificationDetail={userVerificationRequestInfo?._id}
            experienceCheckId={experienceCheckId}
            experienceCheckInfo={experienceCheckInfo}
            getExperienceCheckInfo={getExperienceCheckInfo}
            taskDetail={taskDetail}
            flag={flag}
          />
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { getBase64 } from "../../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import { Card } from "primereact/card";

export default function Inefficiency({ caseId, checkId }) {
  let user = JSON.parse(localStorage.getItem("user"));

  const [remark, setRemark] = useState("");
  const [fileUpload, SetFileUplaod] = useState([]);
  const [inefficiencyInfo, setInefficiencyInfo] = useState([]);

  useEffect(() => {
    if (checkId !== null) {
      getInefficiency(checkId);
    }
  }, [checkId]);

  useEffect(() => {}, [fileUpload]);

  const getInefficiency = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "inefficiency/ByCheckId/link";
    } else {
      url = "inefficiency/ByCheckId";
    }
    try {
      const response = await ApiService.get(`${url}/${id}`);
      setInefficiencyInfo(response?.data);
      // Prefill the remark field
      if (response?.data?.length > 0) {
        setRemark(response.data[0].remark);
        // Prefill the files
        if (response.data[0].files && response.data[0].files.length > 0) {
          const fileUrls = response.data[0].files.map((file) => ({
            name: file.name,
            url: file.url,
          }));
          SetFileUplaod(fileUrls);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCaseIsInefficiency = async (action) => {
    let body = {
      isInefficiency: action,
    };
    ApiService.patch(`userVerificationRequest/${caseId}`, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const createInefficiency = async () => {
    const body = {
      caseId: caseId,
      checkId: checkId,
      remark: "",
      files: [],
    };
    try {
      await ApiService.post("inefficiency", body);
      toast.success("Insufficiency Added successfully");
      getInefficiency(checkId);
      updateCaseIsInefficiency(true);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const updateInefficiency = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "inefficiency/link";
    } else {
      url = "inefficiency";
    }
    const body = {
      caseId: caseId,
      checkId: checkId,
      remark: remark,
      files: fileUpload,
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      toast.success("Insufficiency updated successfully");
      getInefficiency(checkId);
      updateCaseIsInefficiency(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setRemark(value);
  };

  const handleFileUpload = async (event, category) => {
    const files = event.files;
    const fileData = [];
    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("Insufficiency File successfully uploaded");
        break;
        // case "uploadProof":
        //   SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        //   toast.success("Upload proof successfully uploaded");
        //   break;
        // default:
        //   break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Insufficiency</h5>
          </div>
          {inefficiencyInfo?.length === 0 &&
            (user?.role === "company" ||
              user?.role === "BCA" ||
              user?.role === "BCAStaff") && (
            <Button
              label="Add Insufficiency"
              type="button"
              onClick={() => createInefficiency()}
              className="w-[170px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
            />
          )}
        </div>
        {inefficiencyInfo?.length !== 0 && (
          <div className="flex flex-col justify-end">
            <Card className="border-2" style={{ borderColor: "#14b8a6" }}>
              <div className="row my-2">
                <div className="col-md-12">
                  <label className="small mb-1" htmlFor="remark">
                    Remark
                  </label>
                  <InputTextarea
                    className="form-control"
                    type="text"
                    name="remark"
                    value={remark}
                    onChange={handleChange}
                    rows={5}
                    cols={30}
                  />
                </div>
                <div className="col-md-12 my-2">
                  {inefficiencyInfo[0]?.files?.length === 0 ? (
                    <div>
                      <label className="small mb-1" htmlFor="fileUpload">
                        File Upload
                      </label>
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        // onChange={handleChange}
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "file")}
                      />
                    </div>
                  ) : (
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {inefficiencyInfo[0]?.files?.map((fileUrl, index) => {
                        return (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image_${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <div className="flex items-center justify-center">
                              <a
                                href={fileUrl}
                                download={`file_Image_${index}`}
                                className="text-center text-blue-500 underline cursor-pointer"
                              >
                                Download
                              </a>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <Button
                  label="Save"
                  type="submit"
                  onClick={() => updateInefficiency(inefficiencyInfo[0]?._id)}
                  className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
}

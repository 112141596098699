import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import { InputTextarea } from "primereact/inputtextarea";
import Inefficiency from "../../components/UserProfileView/Checklist/Inefficiency.jsx";
import { educationCategories, getSeverity } from "../../utils/constants.js";

const EducationDetail = ({
  userVerificationDetail,
  taskDetail,
  educationCheckInfo,
  geteducationCheckInfo,
  flag,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);

  useEffect(() => {}, [fileUpload]);
  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "education",
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {
  //       // toast.success("Task Item Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const onInputChange = (e, index) => {
    setData((prevData) => {
      // Create a copy of the original data array
      const newData = [...prevData];
      // Update the specific object at the given index
      newData[index] = {
        ...newData[index],
        education: {
          ...newData[index].education,
          [e.target.name]: e.target.value,
        },
      };
      // Return the updated array
      return newData;
    });
  };

  useEffect(() => {
    setData(educationCheckInfo);
  }, [educationCheckInfo]);

  const updateEducationDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      education: {
        ...data[index]?.education,
        reportUrl: [...data[index]?.education?.reportUrl, ...fileUpload],
        remark: data[index]?.education?.remark,
        uploadProofUrl: [
          ...data[index]?.education?.uploadProofUrl,
          ...uploadProof,
        ],
      },
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      if (flag === "false") {
        geteducationCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, e, id) => {
    e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.patch(`educationCheck/education/status/${id}`, body);
      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // getDiscreteCallInfo(userVerificationDetail);
      geteducationCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const handleFileUpload = async (event, category) => {
    const files = event.files;
    const fileData = [];
    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`border-2 ${index === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex justify-between">
                    {item?.education?.status === "Pending" ? (
                      <div className="flex flex-row gap-1">
                        <div
                          role="presentation"
                          onClick={(e) => {
                            updateCheckStatus("Approved", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Approve"
                            severity="success"
                            icon="pi text-white pi-check-circle"
                            size="small"
                          />
                        </div>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            updateCheckStatus("Rejected", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Reject"
                            icon="pi text-white pi-times-circle"
                            severity="danger"
                            size="small"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>{getSeverity(item?.education?.status)}</div>
                      </>
                    )}
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      University Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="unversityName"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.education?.unversityName || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Year Of Passing
                    </label>
                    <InputText
                      className="form-control"
                      type="number"
                      name="yearOfPassing"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.education?.yearOfPassing || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Qualification
                    </label>
                    <Dropdown
                      value={item?.education?.qualification || ""}
                      onChange={(e) => onInputChange(e, index)}
                      options={educationCategories}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Qualification"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="qualification"
                      name="qualification"
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        School Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="schoolAddress"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.schoolAddress || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        University Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityAddress"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.unversityAddress || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        School Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="schoolName"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.schoolName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Enrollment no./Roll.no
                    </label>
                    <InputText
                      className="form-control"
                      type="number"
                      name="enrollmentNumber"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.education?.enrollmentNumber || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Roll no.
                      </label>
                      <InputText
                        className="form-control"
                        type="number"
                        name="rollNumber"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.rollNumber || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Final result/grade
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="grade"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.grade || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}
                </div>

                <div className="row my-2">
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Mode Of Education
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="modeOfEducation"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.modeOfEducation || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="small mb-1" htmlFor="reportNumber">
                        Period Of Study
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfStudy"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.periodOfStudy || ""}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        If there is any gap/break in education mention here
                        along with reasons:
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="comment"
                        onChange={(e) => onInputChange(e, index)}
                        value={item?.education?.comment || ""}
                        disabled={flag === "true"}
                        rows={5}
                        cols={30}
                      />
                    </div>
                  )}

                  <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload
                    </label>
                    {item?.education?.reportUrl?.length === 0 ||
                    editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          multiple
                          auto
                          accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.education?.reportUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>

                  {user?.role !== "user" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Remark
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="remark"
                          onChange={(e) => onInputChange(e, index)}
                          value={item?.education?.remark || ""}
                          disabled={flag === "false"}
                        />
                      </div>
                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof
                        </label>
                        {item?.education?.uploadProofUrl?.length === 0 ? (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            customUpload={true}
                            maxFileSize={50000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            disabled={flag === "false"}
                          />
                        ) : (
                          ""
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.education?.uploadProofUrl || []).map(
                            (fileUrl, index) => (
                              <li
                                key={Math.random().toString(36).substr(2, 9)}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div className="h-[200px]">
                                  <img
                                    src={fileUrl}
                                    alt={`file_Image ${index}`}
                                    className="h-[200px] w-[200px] object-contain"
                                  />
                                </div>
                                <a
                                  href={fileUrl}
                                  download={`file_Image_${index}`}
                                  className="text-center"
                                >
                                  Download
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
                <div></div>
                <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                  {flag === "false" && item?.education?.status === "Pending" ? (
                    item?.education?.reportUrl?.length > 0 ? (
                      <Button
                        label="Edit"
                        type="button"
                        onClick={(e) => {
                          handleEdit(e);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {item?.education?.status === "Pending" ? (
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateEducationDetails(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.education?.status)}</div>
                    </div>
                  ) : (
                    <div>{getSeverity(item?.education?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default EducationDetail;

/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";

const AddressDetails = ({
  userVerificationDetail,
  taskDetail,
  addressCheckInfo,
  getAddressCheckInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const categories = [{ name: "Current" }, { name: "Permanent" }];
  const [fileUpload, SetFileUplaod] = useState([]);
  const [editable, setEditable] = useState(false);
  const [uploadProof, SetUploadProof] = useState([]);

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "addressCheck/link";
    } else {
      url = "addressCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const onInputChange = (e, index) => {
    setData((prevData) => {
      // Create a copy of the original data array
      const newData = [...prevData];
      // Update the specific object at the given index
      newData[index] = {
        ...newData[index],
        address: {
          ...newData[index].address,
          [e.target.name]: e.target.value,
        },
      };
      // Return the updated array
      return newData;
    });
  };

  useEffect(() => {
    setData(addressCheckInfo);
    SetFileUplaod(data?.address?.reportUrl || []);
  }, [addressCheckInfo]);

  const updateDiscreteCalls = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "addressCheck/link";
    } else {
      url = "addressCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      address: {
        ...data[index]?.address,
        reportUrl: [...data[index]?.address?.reportUrl, ...fileUpload],
        remark: data[index]?.address?.remark,
        uploadProofUrl: [
          ...data[index]?.address?.uploadProofUrl,
          ...uploadProof,
        ],
      },
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Address details updated successfully");
      if (flag === "false") {
        getAddressCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const updateCheckStatus = async (status, e, id) => {
    e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.patch(`addressCheck/address/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getAddressCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "address",
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const handleFileUpload = async (event, category) => {
    const files = event.files;
    const fileData = [];
    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };
  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`border-2 ${index === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex justify-between">
                    {item?.address?.status === "Pending" ? (
                      <div className="flex flex-row gap-1">
                        <div
                          role="presentation"
                          onClick={(e) => {
                            // confirm1(item?._id);
                            updateCheckStatus("Approved", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Approve"
                            severity="success"
                            icon="pi text-white pi-check-circle"
                            size="small"
                          />
                        </div>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            // confirm1(item?._id);
                            updateCheckStatus("Rejected", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Reject"
                            icon="pi text-white pi-times-circle"
                            severity="danger"
                            size="small"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>{getSeverity(item?.address?.status)}</div>
                      </>
                    )}
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Address Type
                    </label>
                    <Dropdown
                      value={item?.address?.typeOfAddress || ""}
                      onChange={(e) => onInputChange(e, index)}
                      options={categories}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Type"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="typeOfAddress"
                      name="typeOfAddress"
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Landmark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="landmark"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.landmark || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      City
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="city"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.city || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      State
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="state"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.state || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Pin Code
                    </label>
                    <InputText
                      className="form-control"
                      keyfilter="int"
                      maxLength={6}
                      name="pincode"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.pincode || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Contact Number
                    </label>
                    <InputText
                      className="form-control"
                      keyfilter="int"
                      maxLength={10}
                      name="contactNumber"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.contactNumber || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Period of Stay
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="periodOfStay"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.periodOfStay || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Pancard Verification
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="panCardVerification"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.panCardVerification || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Bank Account Verification
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="bankAccVerification"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.bankAccVerification || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Voter Id Verification
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="voterIdVerification"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.voterIdVerification || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Ownership
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="ownership"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.address?.ownership || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload
                    </label>
                    {item?.address?.reportUrl?.length === 0 ||
                    editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.address?.reportUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>

                <div className="col-md-8">
                  <label className="small mb-1" htmlFor="clientType">
                    Remark
                  </label>
                  <InputText
                    className="form-control"
                    type="text"
                    name="remark"
                    onChange={(e) => onInputChange(e, index)}
                    value={item?.address?.remark || ""}
                    disabled={flag === "false"}
                  />
                </div>

                <div className="col-md-12 my-2">
                  <label className="small mb-1" htmlFor="clientType">
                    Upload Proof
                  </label>
                  {item?.address?.uploadProofUrl?.length === 0 && (
                    <FileUpload
                      name="fileUpload[]"
                      auto
                      multiple
                      accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                      customUpload={true}
                      maxFileSize={50000000}
                      uploadHandler={(e) => handleFileUpload(e, "uploadProof")}
                      disabled={flag === "false"}
                    />
                  )}
                  <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                    {(item?.address?.uploadProofUrl || []).map(
                      (fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`file_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`file_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div></div>

                <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                  {flag === "false" && item?.address?.status === "Pending" ? (
                    item?.address?.reportUrl?.length > 0 ? (
                      <Button
                        label="Edit"
                        type="button"
                        onClick={(e) => {
                          handleEdit(e);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {item?.address?.status === "Pending" ? (
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateDiscreteCalls(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.address?.status)}</div>
                    </div>
                  ) : (
                    <div>{getSeverity(item?.address?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default AddressDetails;

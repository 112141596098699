import React, { useState } from "react";
import { UserRegisterPage } from "./UserRegistrationPage.jsx";
import { BCARegisterPage } from "./BCARegistrationPage.jsx";
import { CompanyRegisterPage } from "./CompanyRegistrationPage.jsx";
const Signup = () => {
  const tabs = [];

  // if (process.env.REACT_APP_ENABLE_USER_SIGNUP === "true") {
  //   tabs.push({
  //     id: 0,
  //     name: "User",
  //     content: "User",
  //   });
  // }

  tabs.push(
    // {
    //   id: 1,
    //   name: "BCA",
    //   content: "BCA",
    // },
    {
      id: 2,
      name: "Company",
      content: "Company",
    }
  );

  const [tab, setTab] = useState(tabs[0].name);

  const tabHandler = (value) => {
    setTab(value);

    switch (value) {
    // case "User":
    //   setTab("User");
    //   break;
    // case "BCA":
    //   setTab("BCA");
    //   break;
    case "Company":
      setTab("Company");
      break;

    default:
      setTab("User");
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-col items-center min-h-screen pt-6 sm:pt-0 bg-cover backgrondStyle">
          <div className="mt-3">
            <ul className="md:flex md:space-x-8 space-x-2 cursor-pointer">
              <div className="tab-container">
                <div className="tab-nav">
                  <div className="tab h-[57px] is-desktop rounded-[26px] ">
                    {tabs &&
                      tabs.length > 0 &&
                      tabs.map((tabValue) => (
                        <button
                          //disabled={mainLoader}
                          type="button"
                          onClick={() => {
                            tabHandler(tabValue.name);
                          }}
                          key={tabValue.id}
                          className={` m-auto md:text-[18px] m-[9px] h-[39px] w-[131px]  text-md mr-[20px] text-dark-gray hover:text-purple pb-[3px]   hover:border-purple font-medium ${
                            tab === tabValue.name
                              ? "text-white border-purple  bg-gradient-to-b from-sky-600 to-sky-800 rounded-[19.50px] "
                              : "text-dark-gray border-transparent "
                          }`}
                        >
                          {tabValue.content}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </ul>
          </div>
          {/* <div className="w-[539px] h-[636px] bg-white rounded-[50px] shadow border border-stone-300 border-opacity-80 backdrop-blur-[200px]" /> */}

          {tab == "BCA" && (
            <>
              <BCARegisterPage />
            </>
          )}
          {tab == "User" && (
            <>
              <UserRegisterPage />
            </>
          )}
          {tab == "Company" && (
            <>
              <CompanyRegisterPage />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Signup;

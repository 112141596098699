import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";

const DrugDetails = ({
  userVerificationDetail,
  taskDetail,
  drugCheckInfo,
  getDrugCheckInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [list, setList] = useState([]);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);

  useEffect(() => {
    ApiService.get("userDocument/drug").then((res) => {
      setList(res?.data);
    });
  }, []);
  const getCertificateInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then((res) => {
        res?.data?.docs[0]; // Assuming there's only one item in the array
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const onInputChange = (e, index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        drug: {
          ...newData[index].drug,
          [e.target.name]: e.target.value,
        },
      };
      // Return the updated array
      return newData;
    });
  };

  useEffect(() => {
    setData(drugCheckInfo);
    SetFileUplaod(data?.drug?.reportUrl || []);
  }, [drugCheckInfo]);

  const updateCertificateDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "drugCheck/link";
    } else {
      url = "drugCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      drug: {
        ...data[index]?.drug,
        reportUrl: [...data[index]?.drug?.reportUrl, ...fileUpload],
        remark: data[index]?.drug?.remark,
        uploadProofUrl: [...data[index]?.drug?.uploadProofUrl, ...uploadProof],
      },
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      getCertificateInfo(userVerificationDetail);
      toast.success("Drug details updated successfully");
      if (flag === "false") {
        getDrugCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleFileUpload = async (event, category) => {
    const files = event.files;
    const fileData = [];
    for (let file of files) {
      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      // Handle error uploading files
    }
  };

  const updateCheckStatus = async (status, e, id) => {
    e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.patch(`drugCheck/drug/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getDrugCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "drug",
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`border-2 ${index === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex justify-between">
                    {item?.drug?.status === "Pending" ? (
                      <div className="flex flex-row gap-1">
                        <div
                          role="presentation"
                          onClick={(e) => {
                            // confirm1(item?._id);
                            updateCheckStatus("Approved", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Approve"
                            severity="success"
                            icon="pi text-white pi-check-circle"
                            size="small"
                          />
                        </div>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            // confirm1(item?._id);
                            updateCheckStatus("Rejected", e, item?._id, index);
                          }}
                        >
                          <Button
                            label="Reject"
                            icon="pi text-white pi-times-circle"
                            severity="danger"
                            size="small"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>{getSeverity(item?.drug?.status)}</div>
                      </>
                    )}
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Name
                    </label>
                    <Dropdown
                      value={item?.drug?.name || ""}
                      onChange={(e) => onInputChange(e, index)}
                      options={list}
                      optionLabel="title"
                      optionValue="title"
                      placeholder="Select Name"
                      filter
                      className="w-full md:w-14rem"
                      inputId="name"
                      name="name"
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Clinic/Lab name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="clinicName"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drug?.clinicName || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Doctor Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="doctorName"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drug?.doctorName || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Clinic/Lab address
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="clinicAddress"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drug?.clinicAddress || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Test Date
                    </label>
                    <InputText
                      className="form-control"
                      type="date"
                      name="testDate"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drug?.testDate || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Report Date
                    </label>
                    <InputText
                      className="form-control"
                      type="date"
                      name="reportDate"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drug?.reportDate || ""}
                      disabled={flag === "true"}
                    />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload
                    </label>
                    {item?.drug?.reportUrl?.length === 0 ||
                    editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          multiple
                          auto
                          accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                          customUpload={true}
                          maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.drug?.reportUrl || []).map((fileUrl, index) => (
                        <li
                          key={Math.random().toString(36).substr(2, 9)}
                          className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                        >
                          <div className="h-[200px]">
                            <img
                              src={fileUrl}
                              alt={`file_Image ${index}`}
                              className="h-[200px] w-[200px] object-contain"
                            />
                          </div>
                          <a
                            href={fileUrl}
                            download={`file_Image_${index}`}
                            className="text-center"
                          >
                            Download
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="clientType">
                      Remark
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="remark"
                      onChange={(e) => onInputChange(e, index)}
                      value={item?.drug?.remark || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof
                    </label>
                    {item?.drug?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        customUpload={true}
                        maxFileSize={50000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProof")
                        }
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.drug?.uploadProofUrl || []).map(
                        (fileUrl, index) => (
                          <li
                            key={Math.random().toString(36).substr(2, 9)}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <div className="h-[200px]">
                              <img
                                src={fileUrl}
                                alt={`file_Image ${index}`}
                                className="h-[200px] w-[200px] object-contain"
                              />
                            </div>
                            <a
                              href={fileUrl}
                              download={`file_Image_${index}`}
                              className="text-center"
                            >
                              Download
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                  {flag === "false" && item?.drug?.status === "Pending" ? (
                    item?.drug?.reportUrl?.length > 0 ? (
                      <Button
                        label="Edit"
                        type="button"
                        onClick={(e) => {
                          handleEdit(e);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {item?.drug?.status === "Pending" ? (
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateCertificateDetails(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.drug?.status)}</div>
                    </div>
                  ) : (
                    <div>{getSeverity(item?.drug?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default DrugDetails;

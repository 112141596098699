import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import SexOffenderDetails from "../../StaffMember/SexOffenderDetails.jsx";
import { Button } from "primereact/button";

export default function SexOffenderCheck({
  userVerificationRequestInfo,
  taskDetail,
  info,
  flag,
}) {
  const [
    selectedStaffForSexOffenderCheck,
    setSelectedStaffForSexOffenderCheck,
  ] = useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [sexOffenderCheckInfo, setSexOffenderCheckInfo] = useState([]);
  const [sexOffenderCheckId, setSexOffenderCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        setBCAStaffList(log?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
      }
    }
    getSexOffenderCheckInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  const getSexOffenderCheckInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "sexOffenderCheck/link";
    } else {
      url = "sexOffenderCheck";
    }
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setSexOffenderCheckInfo(res?.data?.docs);
        setSexOffenderCheckId(res?.data?.docs[0]?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSexOffenderCheck = async () => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "sexOffenderCheck/link";
    } else {
      url = "sexOffenderCheck";
    }
    setAddNewLoading(true);
    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      sexOffender: {
        firstName: "",
        lastName: "",
        country: "",
        status: "Pending",
        remark: "",
      },
    };
    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("SexOffender Added successfully");
        setAddNewLoading(false);
        getSexOffenderCheckInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setAddNewLoading(false);
      });
  };
  useEffect(() => {}, [sexOffenderCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );
      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillAddressStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.SexOffenderTaskId
      );

      setSelectedStaffForSexOffenderCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForAddress = async () => {
      if (userVerificationRequestInfo?.SexOffenderTaskId !== null) {
        await prefillAddressStaff(
          userVerificationRequestInfo?.SexOffenderTaskId
        );
      }
    };

    fetchStaffForAddress();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId: selectedStaffForSexOffenderCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "SexOffender",
      };

      if (userVerificationRequestInfo?.SexOffenderTaskId === null) {
        body.checkListItem = sexOffenderCheckInfo;
        await createTask(body);
      } else {
        body.checkListItem = sexOffenderCheckInfo;
        let path = `task/${userVerificationRequestInfo?.SexOffenderTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId: selectedStaffForSexOffenderCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      console.error("Error in API:", error);
      setAssignLoading(false);
    }
  };
  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.patch(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsAssigned = async () => {
    let body = {
      isAssigned: true,
    };
    ApiService.patch(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getSexOffenderCheckInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.patch(path, body);
    if (response) {
      toast.success("Task updated successfully");
      setAssignLoading(false);
      getSexOffenderCheckInfo(userVerificationRequestInfo?._id);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>SexOffender Check</h5>
          </div>

          <div className="flex max-sm:flex-col gap-2">
            {sexOffenderCheckInfo.length > 0 &&
            (user?.role === "BCA" || user?.role === "Vendor") ? (
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForSexOffenderCheck}
                  onChange={(e) => {
                    setSelectedStaffForSexOffenderCheck(e.value);
                  }}
                  options={BCAStaffList}
                  optionLabel="firstName"
                  className="w-[148px] h-[45px] max-sm:w-full"
                  placeholder="Assign Staff"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={sexOffenderCheckInfo.length === 0}
                />
              ) : (
                ""
              )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              {sexOffenderCheckInfo.length > 0 &&
              (user?.role === "BCA" || user?.role === "Vendor") ? (
                  <Button
                    label="Assign"
                    type="button"
                    loading={assignLoading}
                    onClick={() => handleCreateTaskAPI()}
                    className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                ) : (
                  ""
                )}
              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createSexOffenderCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {sexOffenderCheckInfo.length > 0 &&
        sexOffenderCheckInfo !== null &&
        sexOffenderCheckId !== undefined &&
        sexOffenderCheckId !== null &&
        sexOffenderCheckInfo !== undefined && (
        <>
          <SexOffenderDetails
            userVerificationDetail={userVerificationRequestInfo?._id}
            sexOffenderCheckId={sexOffenderCheckId}
            sexOffenderCheckInfo={sexOffenderCheckInfo}
            getSexOffenderCheckInfo={getSexOffenderCheckInfo}
            taskDetail={taskDetail}
            flag={flag}
          />
        </>
      )}
    </>
  );
}

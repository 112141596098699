import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiService from "../../../services/APIServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const BCARegisterPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required("Company Name is required"),
    address: Yup.string().required("Address Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),

    websiteLink: Yup.string().required("Website Link is required"),
    noOfEmployees: Yup.number().required("No of Employees is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      email: "",
      websiteLink: "",
      noOfEmployees: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      delete values.phone;
      const formData = {
        ...values,
        role: "BCA",
      };
      ApiService.post("BCA", formData)
        .then(() => {
          setIsLoading(false);
          toast.success("OTP sent to registered mail");
          navigate("/verify", {
            state: {
              email: formik.values.email,
              formType: "signup",
            },
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error("BCA is already Registered");
        });
    },
  });
  return (
    <>
      <div className="w-full p-[46px] rounded-[50px] bg-white shadow-md sm:max-w-lg border border-stone-300 border-opacity-80 backdrop-blur-[200px] ">
        {/* <div className="text-black text-xl font-medium flex justify-center">
          <img src={logo} alt="coinCircleTrust" className="w-[65%]" />
        </div> */}
        <div className="flex justify-between h-[55px] flex-row mt-[30px]">
          <div className="text-black text-[30px] font-medium ">Sign Up</div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="email" className="text-black text-base font-normal">
              Email
            </label>
            <div className="flex flex-col items-start">
              <input
                type="email"
                className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email}</div>
          ) : null}
          <div className="mt-2 row">
            <div className="col-md-6">
              <label
                htmlFor="name"
                className="text-black text-base font-normal"
              >
                Organization Name
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-500">{formik.errors.name}</div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="name"
                className="text-black text-base font-normal"
              >
                Phone Number
              </label>
              <div className="flex flex-col items-start">
                <PhoneInput
                  containerClass="my-container-class mt-1"
                  inputClass="my-input-class"
                  enableAreaCodes={true}
                  onBlur={formik.handleBlur("phoneNumber")}
                  onChange={(value) =>
                    formik.setFieldValue("phoneNumber", value)
                  }
                  value={formik.values.phoneNumber}
                  country="in"
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="text-red-500">
                    {formik.errors.phoneNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <label
                htmlFor="name"
                className="text-black text-base font-normal"
              >
                Enter Your Website Link
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  name="websiteLink"
                  value={formik.values.websiteLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.websiteLink && formik.errors.websiteLink ? (
                  <div className="text-red-500">
                    {formik.errors.websiteLink}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="name"
                className="text-black text-base font-normal"
              >
                No. of Employees
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  name="noOfEmployees"
                  value={formik.values.noOfEmployees}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                    formik.setFieldValue("noOfEmployees", sanitizedValue);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.noOfEmployees && formik.errors.noOfEmployees ? (
                  <div className="text-red-500">
                    {formik.errors.noOfEmployees}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="name" className="text-black text-base font-normal">
              Address
            </label>
            <div className="flex flex-col items-start">
              <input
                type="text"
                className="h-[57px] p-[16px] bg-white rounded-[10px] shadow border border-zinc-400 border-opacity-95 block w-full mt-1  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          {formik.touched.address && formik.errors.address ? (
            <div className="text-red-500">{formik.errors.address}</div>
          ) : null}
          <div className="mt-3">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full m-auto text-white h-[54px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Wait...
                </>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
          <div className="flex justify-center mt-3">
            <span className="text-black text-[13px] font-normal">
              Have an Account ?
            </span>
            &nbsp;
            <span
              role="presentation"
              className="text-sky-800 text-[13px] cursor-pointer font-normal"
              onClick={() => navigate("/login")}
            >
              Sign In
            </span>
          </div>
        </form>
      </div>
    </>
  );
};
